<template>
  <v-card
    color="#F4F9FF"
    style="display:flex; flex-direction: row; min-height: 100%; width: 100%"
  >
    <v-layout style="display: flex; flex-direction: column;">
      <label class="item-title">Notification Settings</label>
      <label class="email-title ml-10 mb-5">
        Choose type of you want to receive
      </label>
      <div>
        <v-row class="px-6">
          <v-col
            class="px-3"
            sm="6"
            cols="12"
            v-for="item in items"
            :key="item.field"
          >
            <v-checkbox v-model="profile[item.field]" :label="item.title" />
          </v-col>
        </v-row>
      </div>
      <div style="display:flex; justify-content:flex-end; flex-direction: row;">
        <v-btn
          rounded
          width="200"
          color="primary"
          class="mb-3 mr-3 mt-5"
          @click="onUpdate"
          :loading="loading"
          v-text="'Save'"
        />
      </div>
    </v-layout>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      errorMessage: null,
      snackbar: false,
      items: [
        {
          title: "Weekly report",
          field: "notification_weekly_report",
        },
        {
          title: "Password change",
          field: "notification_password_change",
        },
        {
          title: "All Activities",
          field: "notification_all_activies",
        },
        {
          title: "New request arrived",
          field: "notification_new_request",
        },
        {
          title: "New referral arrived",
          field: "notification_new_referral",
        },
        {
          title: "New message received",
          field: "notification_new_message",
        },
      ],
      profile: {},
    };
  },
  methods: {
    ...mapActions("auth", {
      fetchProfile: "fetchProfile",
      updateAdmin: "updateAdmin",
    }),
    onUpdate() {
      this.loading = true;
      var params = { _id: this.profile._id };
      this.items.map((i) => {
        params[i.field] = this.profile[i.field];
      });
      this.updateAdmin(params)
        .then(() => (this.loading = false))
        .catch((error) => {
          this.loading = false;
          if (this.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  created() {
    this.loading = true;
    this.fetchProfile()
      .then((data) => {
        this.loading = false;
        this.profile = { ...data };
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
};
</script>
<style>
.item-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
}
.name-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 20px;
}
.email-title {
  color: #6e3dc6;
  font-family: "Poppins-Medium";
  font-size: 15px;
}
.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}

.v-sheet::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-sheet::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-sheet::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-sheet::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
